body {
    margin: 0 auto;
    height: 100%;
    /*max-width: 1200px;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    height: 100%;
}

.active {
    color: red !important;
}

#htmlDivContent table {
    border-spacing: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.16);
    border-top: 1px solid rgba(0, 0, 0, 0.16);
}

#htmlDivContent table td {
    border-right: 1px solid rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding: 4px;
}

#htmlDivContent table th {
    background-color: #64b5f6;
    border-right: 1px solid rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding: 4px;
}

